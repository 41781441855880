













import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class FileQuestion extends Vue {
  @Prop({default: () => []}) value!: string;

  private items = ['jpeg', 'pdf', 'doc', 'docx'];
}
