import {Module} from 'vuex-class-modules';
import store from '@/store';
import {questionItemApi as api} from '@/api';
import {
  QuestionItemAttributes as Attributes,
  QuestionItemCreate as Create,
  QuestionItemResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.QuestionItem,
    attributes: {
      id: 0,
      label: '',
      questionId: 0,
      value: '',
      userCreated: {id: 0, firstName: '', lastName: ''},
      userModified: {id: 0, firstName: '', lastName: ''},
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class QuestionItemModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {}

export const questionItemModule = new QuestionItemModule(
  {
    store,
    name: ResourceType.QuestionItem,
  },
  () => api,
  defaultItem
);
