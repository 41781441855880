











































import Vue from 'vue';
import Component from 'vue-class-component';
import {surveyModule} from '@/store/modules';
import {SurveyResource} from '@/interfaces';
import SurveyType from '@/components/ui/SurveyType.vue';
import SurveyStatusChip from '@/components/ui/SurveyStatusChip.vue';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import {Prop} from 'vue-property-decorator';

@Component({
  components: {CreatedModifiedCell, SurveyStatusChip, SurveyType},
})
export default class SurveyInfo extends Vue {
  @Prop() private surveyId!: string;

  get busy(): boolean {
    return surveyModule.busy;
  }

  get survey(): SurveyResource | null {
    const survey = surveyModule.find(this.surveyId);
    if (!survey) {
      return null;
    }
    return survey;
  }
}
