


































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage} from '@/interfaces';
import SurveyFieldTypeAutocomplete from '@/components/surveys-edit/SurveyFieldTypeAutocomplete.vue';
import {surveyRecipientModule} from '@/store/modules';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {
  isIntegrated,
  isTargeted,
  recipientCsvHeaders,
  rulesRequired,
  sleep,
} from '@/utility';
import DateRangeQuestion from '@/components/questions/DateRangeQuestion.vue';
import DateTimeRangeQuestion from '@/components/questions/DateTimeRangeQuestion.vue';
import FileQuestion from '@/components/questions/FileQuestion.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import DialogActions from '@/components/ui/DialogActions.vue';
import Dialog from '@/components/ui/Dialog.vue';
import QuestionDialogItemList from '@/components/question-collections/QuestionDialogItemList.vue';
import * as Papa from 'papaparse';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import {RecipientType, ResourceType, SurveyType} from '@/enums';
import {surveyRecipientApi} from '@/api';
import {Prop} from 'vue-property-decorator';
import {mdiAlertOctagonOutline, mdiCheckCircleOutline} from '@mdi/js';

@Component({
  components: {
    ButtonDeleteAction,
    QuestionDialogItemList,
    Dialog,
    DialogActions,
    DialogAlert,
    FileQuestion,
    DateTimeRangeQuestion,
    DateRangeQuestion,
    RequiredLabel,
    SurveyFieldTypeAutocomplete,
  },
})
export default class CsvDialog extends Vue {
  @Prop() private surveyRecipientType: RecipientType = RecipientType.Targeted;
  @Prop() private surveyType!: SurveyType;
  private alert: AlertMessage | null = null;
  private complete = false;
  private csvFile: string | null = null;
  private headers = recipientCsvHeaders;
  private items: Record<string, string | boolean>[] = [];
  private saving = false;
  private search = '';
  private rulesRequired = rulesRequired;
  private uploaded = false;
  private valid = false;
  private iconError = mdiAlertOctagonOutline;
  private iconSuccess = mdiCheckCircleOutline;
  private isTargeted = isTargeted;

  get busy(): boolean {
    return surveyRecipientModule.busy;
  }

  get deleting(): boolean {
    return surveyRecipientModule.deleting;
  }

  get loading(): boolean {
    return surveyRecipientModule.loading;
  }

  async input(file: File) {
    this.items = (await this.readCsv(file)) as Record<
      string,
      string | boolean
    >[];
    this.validate();
    this.uploaded = true;
  }

  validate(): void {
    this.valid = true;
    this.items = this.items.map((row, index) => {
      row.id = String(index);
      if (!row.mFormsId && isIntegrated(this.surveyType)) {
        row.error = true;
      }
      if (!row.companyName && isIntegrated(this.surveyType)) {
        row.error = true;
      }
      if (!row.email) {
        row.error = true;
      }
      if (row.error) {
        this.valid = false;
      }
      return row;
    });
  }

  async readCsv(file: File) {
    return new Promise(resolve => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          console.info('Complete', results.data.length, 'records.');
          console.log(results.data);
          resolve(results.data);
        },
      });
    });
  }

  deleteItem(id: string) {
    Vue.delete(this.items, String(id));
    this.validate();
  }

  cancel() {
    this.$emit('close');
  }

  async save() {
    try {
      this.saving = true;
      if (process.env.NODE_ENV === 'development') {
        await sleep();
      }
      await surveyRecipientApi.postMulti(
        this.items.map(item => ({
          type: ResourceType.SurveyRecipient,
          attributes: {
            companyId: item.mFormsId,
            surveyId: Number(this.$route.params.id),
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            accountManagerEmail: item.accountManagerEmail,
            accountManagerName: item.accountManagerName,
            type: this.surveyRecipientType,
          },
        }))
      );
      this.complete = true;
      this.$emit(
        'close',
        `${this.items.length} recipients imported successfully`
      );
    } catch (err) {
      console.error(err);
      this.alert = {message: 'Could not import recipients', type: 'error'};
    } finally {
      this.saving = false;
    }
  }
}
