var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-text-field',{attrs:{"solo":"","id":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"row-not-clickable",attrs:{"headers":_vm.headers,"items":_vm.surveyRecipients,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.accountManager",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("mailto:" + (item.attributes.accountManagerEmail))}},[_vm._v(_vm._s(item.attributes.accountManagerName))])]}},{key:"item.attributes.communitechCompany",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.attributes.communitechCompany ? item.attributes.communitechCompany.name : 'N/A'))])]}}],null,true)},[_vm._v(" "+_vm._s(item.attributes.communitechCompanyId ? item.attributes.communitechCompanyId : 'N/A')+" ")])]}},{key:"item.attributes.url",fn:function(ref){
var item = ref.item;
return [(item.attributes.url)?_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(function () { return _vm.copyToClipboard(item.attributes.url); }),expression:"() => copyToClipboard(item.attributes.url)"}],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (function () {})($event)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconCopy))])],1):_vm._e()]}},{key:"item.attributes.status",fn:function(ref){
var item = ref.item;
return [_c('RecipientStatusChip',{attrs:{"status":item.attributes.status}})]}},{key:"item.createdModified",fn:function(ref){
var item = ref.item;
return [_c('CreatedModifiedCell',{attrs:{"created-date":item.attributes.created,"modified-date":item.attributes.modified}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('ButtonDeleteAction',{attrs:{"disabled":!_vm.canDelete(item.attributes.status) ||
                  _vm.deletingIds.includes(String(item.id)),"loading":_vm.deletingIds.includes(String(item.id))},on:{"click":function($event){_vm.openConfirmDialog(String(item.id))}}})],1)]}}],null,true)},[(_vm.canDelete(item.attributes.status))?_c('span',[_vm._v("Delete this recipient")]):_vm._e(),(!_vm.canDelete(item.attributes.status))?_c('span',[_vm._v("Recipients in the Completed or Sent status cannot be removed.")]):_vm._e()])]}}])}),(_vm.confirmDialogShown)?_c('DeleteRecipientConfirmDialog',{attrs:{"recipient-id":_vm.deleteRecipientId},on:{"close":_vm.closeConfirmDialog}}):_vm._e(),_c('NotificationBar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }