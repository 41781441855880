























































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, Snackbar} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {mdiTrashCan} from '@mdi/js';
import {questionItemModule} from '@/store/modules/questionItemModule';
import {QuestionItemResource} from '@/interfaces/resources/QuestionItemResource';
import SurveyPageAutocomplete from '@/components/surveys-edit/SurveyPageAutocomplete.vue';
import DialogAlertDense from '@/components/ui/DialogAlertDense.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import {cloneDeep, isEqual} from 'lodash';

@Component({
  components: {
    NotificationBar,
    DialogAlertDense,
    SurveyPageAutocomplete,
    RequiredLabel,
    DialogAlert,
  },
})
export default class QuestionDialogItemListItem extends Vue {
  @Prop() private questionItem!: QuestionItemResource;
  @Prop({default: ''}) private labelSuffix!: string;
  private alert: AlertMessage | null = null;
  private iconDelete = mdiTrashCan;
  private originalItem: QuestionItemResource | null = null;
  private snackbar: Snackbar = {visible: false, message: ''};
  private unsaved = false;

  get deleting(): boolean {
    return questionItemModule.deleting;
  }

  get deletingIds(): string[] {
    return questionItemModule.deletingIds;
  }

  get listing(): boolean {
    return questionItemModule.listing;
  }

  get saving(): boolean {
    return questionItemModule.saving;
  }

  get savingIds(): string[] {
    return questionItemModule.savingIds;
  }

  async deleteItem(fieldId: string) {
    await questionItemModule.delete(fieldId);
  }

  created() {
    this.originalItem = cloneDeep(this.questionItem);
  }

  async save(itemId: string) {
    // just in case this is a number
    itemId = String(itemId);
    try {
      await questionItemModule.save(itemId);
      this.originalItem = cloneDeep(this.questionItem);
      this.unsaved = false;
      this.alert = null;
      this.snackbar.message = 'Item saved';
      this.snackbar.visible = true;
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {
        message: error ? error : 'Error',
        type: 'error',
      };
    }
  }
  input() {
    this.questionItem.attributes.value = this.questionItem.attributes.label;
    this.unsaved = !isEqual(this.originalItem, this.questionItem);
  }
}
