













































































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, SelectItem, SurveyFieldResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {submitterMessageModule, surveyFieldModule} from '@/store/modules';
import SurveyFieldTypeAutocomplete from '@/components/surveys-edit/SurveyFieldTypeAutocomplete.vue';
import Dialog from '@/components/ui/Dialog.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import {rulesRequired} from '@/utility';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import QuestionCollectionAutocomplete from '@/components/surveys-edit/QuestionCollectionAutocomplete.vue';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import SurveyField from '@/components/surveys/SurveyField.vue';
import {InputValidationRules} from 'vuetify';

@Component({
  components: {
    SurveyField,
    ButtonDeleteAction,
    ButtonEditAction,
    QuestionCollectionAutocomplete,
    RequiredLabel,
    DialogAlert,
    Dialog,
    SurveyFieldTypeAutocomplete,
  },
})
export default class ContactSubmittersDialog extends Vue {
  @Prop({default: 'submitted'}) defaultBucket!: string;
  @Prop() surveyId!: string;
  private alert: AlertMessage | null = null;
  private valid = false;
  private rules: InputValidationRules = rulesRequired;
  private data: Record<string, string> = {
    bucket: '',
    surveyId: '',
    body: '',
    subject: '',
    firstNameFieldKey: '',
    lastNameFieldKey: '',
    emailField: '',
  };

  get loading() {
    return surveyFieldModule.loading;
  }

  get saving() {
    return submitterMessageModule.saving;
  }

  get surveyFields(): SurveyFieldResource[] {
    return surveyFieldModule.list;
  }

  get surveyFieldSelectItems(): SelectItem[] {
    return surveyFieldModule.list.map(field => ({
      value: field.attributes.id,
      text: field.attributes.label,
    }));
  }

  async save(): Promise<void> {
    try {
      const message = await submitterMessageModule.loadById('new');
      //let message = submitterMessageModule.find('new');
      message.attributes.surveyId = this.data.surveyId;
      message.attributes.bucket = this.data.bucket;
      message.attributes.body = this.data.body;
      message.attributes.subject = this.data.subject;
      message.attributes.emailFieldKey = this.data.emailFieldKey;
      message.attributes.firstNameFieldKey = this.data.firstNameFieldKey;
      message.attributes.lastNameFieldKey = this.data.lastNameFieldKey;
      message.attributes.status = 'queued';
      await submitterMessageModule.save('new');

      this.$emit('close', 'Message queued for sending');
    } catch (err) {
      this.alert = {message: 'Could not send message', type: 'error'};
    }
  }

  async cancel() {
    this.$emit('close');
  }

  async loadSurveyFields(): Promise<void> {
    await surveyFieldModule.loadMulti({
      filters: {surveyId: this.surveyId},
    });
  }

  async created(): Promise<void> {
    this.data.bucket = this.defaultBucket;
    this.data.surveyId = this.surveyId;
    await this.loadSurveyFields();
  }
}
