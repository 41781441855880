





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  JsonApiFilters,
  SelectItem,
  Snackbar,
  SurveyRecipientResource,
} from '@/interfaces';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import {surveyRecipientModule} from '@/store/modules';
import {Prop} from 'vue-property-decorator';
import {RecipientStatus} from '@/enums';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import RecipientStatusChip from '@/components/ui/RecipientStatusChip.vue';
import {mdiContentCopy} from '@mdi/js';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import DeleteRecipientConfirmDialog from '@/components/surveys-edit/DeleteRecipientConfirmDialog.vue';

@Component({
  components: {
    DeleteRecipientConfirmDialog,
    NotificationBar,
    RecipientStatusChip,
    ButtonDeleteAction,
    CreatedModifiedCell,
    ButtonEditAction,
  },
})
export default class RecipientsList extends Vue {
  @Prop({default: () => ({})}) private filters!: JsonApiFilters;
  private search = '';
  private headers: SelectItem[] = [
    {text: 'mFormsID', value: 'attributes.communitechCompany'},
    {text: 'First Name', value: 'attributes.firstName'},
    {text: 'Last Name', value: 'attributes.lastName'},
    {text: 'Email', value: 'attributes.email'},
    {text: 'URL', value: 'attributes.url'},
    {text: 'Status', value: 'attributes.status'},
    {text: 'Account Manager', value: 'accountManager'},
    {text: 'Created/Modified', value: 'createdModified'},
    {text: 'Actions', value: 'actions'},
  ];
  private iconCopy = mdiContentCopy;
  private snackbar: Snackbar = {visible: false, message: ''};
  private confirmDialogShown = false;
  private deleteRecipientId = '';

  get surveyRecipients(): SurveyRecipientResource[] {
    return surveyRecipientModule.list;
  }

  canDelete(status: RecipientStatus): boolean {
    return (
      status !== RecipientStatus.Completed && status !== RecipientStatus.Sent
    );
  }

  get loading(): boolean {
    return surveyRecipientModule.listing;
  }

  get deleting(): boolean {
    return surveyRecipientModule.deleting;
  }

  get deletingIds(): string[] {
    return surveyRecipientModule.deletingIds;
  }

  async created() {
    surveyRecipientModule.reset();
    await surveyRecipientModule.loadMulti({filters: this.filters});
  }

  // view(item: SurveyRecipientResource) {
  //   this.$router.push({
  //     name: Route.SurveyRecipients,
  //     params: {id: item.id},
  //   });
  // }

  // remove(id: string) {
  //   surveyRecipientModule.delete(String(id));
  // }

  openConfirmDialog(recipientId: string) {
    this.deleteRecipientId = recipientId;
    this.confirmDialogShown = true;
  }

  closeConfirmDialog(message: string) {
    this.confirmDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  copyToClipboard(item: string) {
    this.snackbar = {visible: true, message: 'Survey URL copied to clipboard'};
    return item;
  }
}
