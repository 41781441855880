


























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {RecipientStatus} from '@/enums';
import {capitalize} from '@/utility';

@Component
export default class RecipientStatusChip extends Vue {
  @Prop() private status!: string;
  private recipientStatus = RecipientStatus;
  private capitalize = capitalize;

  get colorPicker(): string {
    switch (this.status) {
      case 'pending':
        return 'gray';
      case 'queued':
        return 'orange';
      case 'completed':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return '';
    }
  }
}
