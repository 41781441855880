






























import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {mdiPlus} from '@mdi/js';
import {questionItemModule} from '@/store/modules/questionItemModule';
import {QuestionItemResource} from '@/interfaces/resources/QuestionItemResource';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import DialogAlertDense from '@/components/ui/DialogAlertDense.vue';
import QuestionDialogItemListItem from '@/components/question-collections/QuestionDialogItemListItem.vue';
import {questionModule} from '@/store/modules';

@Component({
  components: {
    QuestionDialogItemListItem,
    DialogAlertDense,
    NotificationBar,
    RequiredLabel,
    DialogAlert,
  },
})
export default class QuestionDialogItemList extends Vue {
  @Prop() private questionId!: string;
  @Prop({default: false}) private newItem!: false;
  private alert: AlertMessage | null = null;
  private iconAdd = mdiPlus;
  private fieldItemCreating = false;

  get listing(): boolean {
    return questionItemModule.listing;
  }

  get saving(): boolean {
    return questionItemModule.saving;
  }

  get questionItems(): QuestionItemResource[] {
    return questionItemModule.list.filter(item => item.id !== 'new');
  }

  async created(): Promise<void> {
    try {
      await questionItemModule.loadMulti({
        filters: {questionId: this.questionId},
      });
    } catch (err) {
      if (err.response && err.response.data) {
        console.error(err.response.data);
      } else {
        console.error(err);
      }
    }
  }

  async addItem() {
    this.fieldItemCreating = true;
    try {
      await questionItemModule.loadById('new');
      const field = questionItemModule.find('new');
      field.attributes.questionId = parseInt(this.questionId);
      const item = await questionItemModule.save('new');

      const question = questionModule.find(this.questionId);
      question.attributes.items.push(item.attributes);

      this.fieldItemCreating = false;
      this.alert = null;
    } catch (err) {
      this.fieldItemCreating = false;
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {
        message: error ? error : 'Error',
        type: 'error',
      };
    }
  }

  touched() {
    console.log();
  }
}
