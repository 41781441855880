



























import Vue from 'vue';
import Component from 'vue-class-component';
import SurveyInfo from '@/components/surveys/SurveyInfo.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import Page from '@/components/ui/Page.vue';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import SubmissionList from '@/components/submissions/SubmissionList.vue';
import CsvDialog from '@/components/recipients/CsvDialog.vue';
import RecipientsList from '@/components/recipients/RecipientsList.vue';
import {Snackbar, SurveyResource} from '@/interfaces';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import {surveyModule, surveyRecipientModule} from '@/store/modules';
import NewButton from '@/components/ui/NewButton.vue';
import SurveyNavigation from '@/components/surveys-edit/SurveyNavigation.vue';
import {RecipientType, Route} from '@/enums';

@Component({
  components: {
    SurveyNavigation,
    NewButton,
    NotificationBar,
    RecipientsList,
    CsvDialog,
    SubmissionList,
    CreatedModifiedCell,
    Page,
    PageTitle,
    SurveyInfo,
  },
})
export default class Invitations extends Vue {
  private route = Route;
  private csvDialogShown = false;
  private snackbar: Snackbar = {visible: false, message: ''};
  private recipientType = RecipientType.Open;

  get surveyId(): string {
    return this.$route.params.id;
  }

  get survey(): SurveyResource | null {
    const survey = surveyModule.find(this.surveyId);
    if (!survey) {
      return null;
    }
    return survey;
  }

  closeDialog(message?: string) {
    surveyRecipientModule.reset();
    this.csvDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }
}
