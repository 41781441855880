



















import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {surveyRecipientModule} from '@/store/modules';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import DialogActions from '@/components/ui/DialogActions.vue';
import Dialog from '@/components/ui/Dialog.vue';

@Component({
  components: {
    Dialog,
    DialogActions,
    DialogAlert,
  },
})
export default class DeleteRecipientConfirmDialog extends Vue {
  @Prop() recipientId!: string;
  @Prop({default: 'Delete'}) saveLabel!: string;
  private alert: AlertMessage | null = null;

  get deleting(): boolean {
    return surveyRecipientModule.deleting;
  }

  async cancel() {
    this.$emit('close');
  }

  async remove() {
    try {
      await surveyRecipientModule.delete(String(this.recipientId));

      this.$emit('close', `Recipient has been deleted`);
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {
        type: 'error',
        message: `Error: ${error ? error : ''}`,
      };
    }
  }
}
